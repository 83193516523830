<template>
  <div class="panel__content panel">
    <div class="panel__content--left">
      <div class="panel__header-wrapper">
        <div class="panel__index">
          1
        </div>
        <div class="panel__title">
          {{ $t('modelTraining.chooseFactor') }}
          <remind-popover
            :content="$t('modelTraining.reminder.chooseFactor')"
            icon-name="description"
          />
        </div>
        <div
          v-if="factorData.length"
          class="chosen"
        >
          {{ $t('editing.alreadySelect') }}： {{ selectedFactorList.length }} / {{ factorData.length - 1 }}
          <div class="chosen__resources">
            {{ $t('modelTraining.concurrenceTrainingResources') }}{{ dataSizeUnitTransform(selectedResources + trainingDataUsage.concurrentUsage) }} / {{ dataSizeUnitTransform(trainingDataUsage.maximumConcurrentUsage) }}
          </div>
          <div class="chosen__resources">
            {{ $t('modelTraining.totalTrainingResources') }}{{ dataSizeUnitTransform(selectedResources + trainingDataUsage.totalUsage) }} / {{ dataSizeUnitTransform(trainingDataUsage.maximumTotalUsage) }}
          </div>
          <div
            v-if="!isUnlimited && (selectedResources > trainingDataUsage.maximumConcurrentUsage || selectedResources + trainingDataUsage.totalUsage > trainingDataUsage.maximumTotalUsage)"
            class="chosen__size-exceed"
          >
            <svg-icon icon-class="alert" />
            {{ $t('modelTraining.exceedResources') }}
          </div>
        </div>
      </div>
      <div class="panel__info-wrapper">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :cell-class-name="cellClass"
          highlight-current-row
          height="100%"
          max-height="100%"
          class="factor-table"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @sort-change="sortData"
        >
          <el-table-column
            :selectable="checkSelectable"
            :reserve-selection="true"
            type="selection"
            width="45"
          />
          <el-table-column
            :sort-orders="['descending', 'ascending']"
            sortable="custom"
            property="primaryAlias"
          >
            <template slot="header">
              {{ $t('modelTraining.columnName') }}
              <svg-icon
                :class="{'descending': sortStatus['primaryAlias'], 'trigger': sortStatus['primaryAlias'] !== null}"
                icon-class="sorting"
              />
            </template>
            <template slot-scope="scope">
              <span
                :class="{'disable-label': !checkSelectable(scope.row)}"
              >
                {{ scope.row.primaryAlias + (!isTrainingTarget(scope.row) ? '' : `(${$t('modelTraining.trainingTarget')})`) }}
              </span>
              <span
                v-if="hasDuplicateSelectedDataColumn(scope.row)"
                class="warning-text"
              >
                <svg-icon
                  class="error"
                  icon-class="error"
                />
                {{ $t('modelTraining.duplicateColumnName') }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :sort-orders="['descending', 'ascending']"
            sortable="custom"
            property="dataQuality"
          >
            <template slot="header">
              {{ $t('modelTraining.isAvaliableUse') }}
              <svg-icon
                :class="{'descending': sortStatus['dataQuality'], 'trigger': sortStatus['dataQuality'] !== null}"
                icon-class="sorting"
              />
            </template>
            <template slot-scope="scope">
              <div v-if="!isTrainingTarget(scope.row) && scope.row.dataQuality.canUse === 'AVAILABLE'">
                {{ $t('modelTraining.availableUse') }}
              </div>
              <div v-else-if="!isTrainingTarget(scope.row) && scope.row.dataQuality.canUse === 'NOT_SUGGEST'">
                {{ $t('modelTraining.notRecommandUse') }}
                <remind-popover
                  :content="dataQualityMessage(scope.row.dataQuality.dataQualityCheckList)"
                  icon-name="exclamation-triangle"
                  icon-color="#FFDF6F"
                />
              </div>
              <div v-else-if="!isTrainingTarget(scope.row) && scope.row.dataQuality.canUse === 'UNAVAILABLE'">
                <span class="disable-label">
                  {{ $t('modelTraining.unAvailableUse') }}
                  <remind-popover :content="dataQualityMessage(scope.row.dataQuality.dataQualityCheckList)" />
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :sort-orders="['descending', 'ascending']"
            sortable="custom"
            property="statsType"
          >
            <template slot="header">
              {{ $t('modelTraining.type') }}
              <svg-icon
                :class="{'descending': sortStatus['statsType'], 'trigger': sortStatus['statsType'] !== null}"
                icon-class="sorting"
              />
            </template>
          </el-table-column>
          <el-table-column
            :sort-orders="['descending', 'ascending']"
            sortable="custom"
            property="memoryUsage"
          >
            <template slot="header">
              {{ $t('modelTraining.resources') }}
              <svg-icon
                :class="{'descending': sortStatus['memoryUsage'], 'trigger': sortStatus['memoryUsage'] !== null}"
                icon-class="sorting"
              />
            </template>
            <template slot-scope="scope">
              <el-popover
                v-if="dataSizeUnitTransform(scope.row.memoryUsage).indexOf('\u2248') > -1"
                popper-class="el-popover--training-reminder"
                trigger="hover"
              >
                {{ $t('modelTraining.reminder.dataSizeApproximateZero') }}
                <div slot="reference">
                  {{ dataSizeUnitTransform(scope.row.memoryUsage) }}
                </div>
              </el-popover>
              <div v-else>
                {{ dataSizeUnitTransform(scope.row.memoryUsage) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :sort-orders="['descending', 'ascending']"
            sortable="custom"
            property="missingPortion"
            show-overflow-tooltip
          >
            <template slot="header">
              {{ $t('modelTraining.missingPortion') }}
              <remind-popover
                :content="$t('modelTraining.reminder.missingPortion')"
                style="margin-right: 6px"
                icon-name="data-explanation"
              />
              <svg-icon
                :class="{'descending': sortStatus['missingPortion'], 'trigger': sortStatus['missingPortion'] !== null}"
                icon-class="sorting"
              />
            </template>
          </el-table-column>
          <el-table-column
            :sort-orders="['descending', 'ascending']"
            sortable="custom"
            property="correlation"
            show-overflow-tooltip
          >
            <template slot="header">
              {{ $t('modelTraining.correlation') }}
              <remind-popover
                :content="$t('modelTraining.reminder.targetRelevance')"
                style="margin-right: 6px"
                icon-name="data-explanation"
              />
              <svg-icon
                :class="{'descending': sortStatus['correlation'], 'trigger': sortStatus['correlation'] !== null}"
                icon-class="sorting"
              />
            </template>
            <template slot-scope="scope">
              <div
                v-if="!isTrainingTarget(scope.row) && isCalculating"
                class="calculating-status-block"
              >
                <spinner size="14" />
                {{ $t('modelTraining.calculating') }}
              </div>
              <div
                v-else-if="!isTrainingTarget(scope.row) && scope.row.calculateFail && !isCalculating"
                style="color: #A7A7A7"
              >
                {{ $t('modelTraining.correlationCalculatingFail') }}
              </div>
              <div
                v-else-if="!isTrainingTarget(scope.row) && !isCalculating"
                class="correlation-container"
              >
                <div class="item-bar">
                  <div
                    :style="{width: scope.row.correlation}"
                    class="item-bar__inner"
                  />
                </div>
                <div>{{ scope.row.correlation }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="panel__content--right">
      <div class="panel__header-wrapper">
        <div class="panel__index">
          2
        </div>
        <div class="panel__title">
          {{ $t('modelTraining.trainingMethod') }}
        </div>
      </div>
      <div class="panel__info-wrapper">
        <div class="input-field">
          <div class="input-field__label">
            {{ $t('modelTraining.primaryMetric') }}
            <remind-popover
              :content="$t('modelTraining.reminder.primaryMetric')"
              icon-name="data-explanation"
            />
          </div>
          <div class="input-field__content-container">
            <div class="input-field__radio-group-container">
              <div
                v-for="(option, index) in primaryMetricList"
                :key="index"
                class="input-radio-group"
              >
                <input
                  :id="option.type"
                  :checked="trainingMethod.primaryMetric === option.type"
                  :value="option.type"
                  class="input-radio"
                  type="radio"
                  @change="trainingMethod.primaryMetric = option.type"
                >
                <label
                  :for="option.type"
                  class="input-radio-label"
                >
                  {{ option.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="input-field">
          <div class="input-field__label">
            {{ $t('modelTraining.trainingStrategy') }}
            <remind-popover
              :content="$t('modelTraining.reminder.trainingTime')"
              icon-name="information-circle"
              icon-color="#ffdf6f"
            />
          </div>
          <div class="input-field__content-container">
            <div class="input-field__radio-group-container">
              <div
                v-for="(option, index) in trainingStrategyList"
                :key="index"
                class="input-radio-group"
              >
                <input
                  :id="option.type"
                  :checked="trainingMethod.trainingStrategy === option.type"
                  :value="option.type"
                  class="input-radio"
                  type="radio"
                  @change="trainingMethod.trainingStrategy = option.type"
                >
                <label
                  :for="option.type"
                  class="input-radio-label"
                >
                  {{ option.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-button-block">
          <button
            :disabled="isProcessing"
            class="btn btn-default"
            @click="trainModel();trackPageFunctionClick('training-model')"
          >
            <span v-if="isProcessing"><svg-icon icon-class="spinner" />{{ $t('button.processing') }}</span>
            <span v-else>{{ $t('button.trainingModel') }}</span>
          </button>
        </div>
      </div>
    </div>
    <remind-dialog
      v-if="isShowRemindDialog"
      :content="$t('modelTraining.resourceOverloading')"
      @closeDialog="cancelDialog"
    />
  </div>
</template>

<script>
import RemindPopover from '@/components/popover/RemindPopover.vue'
import RemindDialog from './RemindDialog'
import { creatModelTrainingTask } from '@/API/ModelTraining'
import { Message } from 'element-ui'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'TrainingGoalConfig',
  components: {
    RemindPopover,
    RemindDialog
  },
  props: {
    targetName: {
      type: String,
      default: ''
    },
    factorData: {
      type: Array,
      default: () => []
    },
    unSavedModelCounts: {
      type: Number,
      default: null
    },
    isCalculating: {
      type: Boolean,
      default: false
    },
    modelTrainingType: {
      type: String,
      default: ''
    },
    primaryMetrics: {
      type: Array,
      default: () => []
    },
    trainingStrategy: {
      type: Array,
      default: () => []
    },
    trainingDataUsage: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isProcessing: false,
      selectedFactorList: [],
      isShowRemindDialog: false,
      selectedResources: 0,
      tableData: [...this.factorData],
      isTarget: false,
      addSpeedyOpion: false,
      sortStatus: {
        primaryAlias: null,
        dataQuality: null,
        statsType: null,
        memoryUsage: null,
        missingPortion: null,
        correlation: null
      },
      trainingMethod: {
        primaryMetric: this.primaryMetrics[0],
        trainingStrategy: localStorage.getItem('demoTrainingStrategy') === 'true' ? 'SPEED' : 'FAST'
      }
    }
  },
  computed: {
    ...mapState('setting', ['locale']),
    modelTrainingGoalId () {
      return Number(this.$route.params.goal_id)
    },
    primaryMetricList () {
      return this.primaryMetrics.map(metric => ({
        type: metric,
        name: `${metric} (${this.$t(`modelTraining.${metric.toLowerCase()}UsageScenarios`)})`
      }))
    },
    trainingStrategyList () {
      return this.trainingStrategy.map(strategy => {
        let time
        switch (strategy) {
          case 'SPEED':
            time = 'seconds'
            break
          case 'FAST':
            time = 'minutes'
            break
          case 'BALANCED':
            time = 'hours'
            break
          case 'ACCURATE':
            time = 'oneDay'
            break
        }
        return {
          type: strategy, name: `${this.$t(`modelTraining.${strategy.toLowerCase()}`)} (${this.$t(`modelTraining.${time}`)})`
        }
      })
    },
    isUnlimited () {
      return this.trainingDataUsage.maximumConcurrentUsage < 0 || this.trainingDataUsage.maximumTotalUsage < 0
    }
  },
  mounted () {
    this.$nextTick(() => {
      const indexStore = {}
      this.tableData.forEach((data, index) => {
        if (this.checkSelectable(data)) {
          if (indexStore[data.primaryAlias]) return
          indexStore[data.primaryAlias] = index
        }
      })
      Object.values(indexStore).forEach((index) => {
        this.$refs.multipleTable.toggleRowSelection(this.tableData[index], true)
      })
    })
  },
  methods: {
    ...mapActions('gtm', ['trackPageFunctionClick']),
    trainModel () {
      if (this.selectedFactorList.length > 0 && this.selectedFactorList.every(factor => factor.missingPortion === '100%')) {
        Message({
          message: this.$t('modelTraining.highMissingCantTrainingModel'),
          type: 'warning',
          duration: 3 * 1000,
          showClose: true
        })
        return
      } else if (this.unSavedModelCounts >= 10) {
        Message({
          message: this.$t('modelTraining.tooManyUnsavedModels'),
          type: 'warning',
          duration: 3 * 1000,
          showClose: true
        })
        return
      } else if (!this.isUnlimited && (this.selectedResources > this.trainingDataUsage.maximumConcurrentUsage || this.selectedResources + this.trainingDataUsage.totalUsage > this.trainingDataUsage.maximumTotalUsage)) {
        this.isShowRemindDialog = true
        return
      }
      this.isProcessing = true
      creatModelTrainingTask({
        factors: this.selectedFactorList.map(factor => {
          return {
            dataColumnId: factor.dataColumnId,
            primaryAlias: factor.primaryAlias,
            statsType: factor.statsType,
            ...(factor.statsType === 'DATETIME' && {
              // dateTimeScopeList: [factor.dateTimeScopeList]
              dateTimeScopeList: ['ORIGINAL']
            })
          }
        }),
        modelTrainingGoalId: this.modelTrainingGoalId,
        primaryMetric: this.trainingMethod.primaryMetric,
        trainingStrategy: this.trainingMethod.trainingStrategy,
        trainingType: this.modelTrainingType
      })
        .then(() => {
          Message({
            message: this.$t('modelTraining.startTraining'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
          this.$emit('done')
        })
        .finally(() => this.isProcessing = false)
    },
    handleSelectionChange (val) {
      this.selectedFactorList = val
      let trainingTargetMemoryUsage = this.factorData[0].memoryUsage
      if (!this.selectedFactorList.length) {
        this.selectedResources = trainingTargetMemoryUsage
        return
      }
      this.selectedResources = trainingTargetMemoryUsage + this.selectedFactorList.map(factor => factor.memoryUsage).reduce((accumulator, currentValue) => accumulator + currentValue)
    },
    isTrainingTarget (row) {
      return row.primaryAlias === this.targetName
    },
    checkSelectable (row) {
      return row.primaryAlias !== this.targetName && row.dataQuality.canUse !== 'UNAVAILABLE'
    },
    cancelDialog () {
      this.isShowRemindDialog = false
    },
    cellClass ({ row }) {
      if (!this.checkSelectable(row)) {
        return 'no-checkbox'
      }
    },
    dataQualityMessage (dataQualityList) {
      let str = ''
      dataQualityList.forEach((dataQuality) => {
        str += this.$t(`dataQualityMessage.${dataQuality.dataQualityKey}`)
      })
      return str
    },
    sortData (column) {
      const orderGroup = [...this.tableData]
      const fieldName = column.prop
      const isDescending = column.order === 'descending'
      let fixedTargetValueList = {
        numeric: null,
        percent: null,
        unicode: null
      }
      if (column.order === 'descending') {
        fixedTargetValueList = { numeric: Number.MAX_SAFE_INTEGER, percent: '999%', unicode: '\uffff' }
        this.tableData = this.sorting(isDescending, orderGroup, fieldName, fixedTargetValueList)
      } else if (column.order === 'ascending') {
        fixedTargetValueList = { numeric: Number.MIN_SAFE_INTEGER, percent: '-1%', unicode: '\u0000' }
        this.tableData = this.sorting(isDescending, orderGroup, fieldName, fixedTargetValueList)
      }
      // else {
      //   for (let i in this.sortStatus) {
      //     this.sortStatus[i] = null
      //   }
      //   this.$refs.multipleTable.clearSort()
      // }
    },
    sorting (isDescending, data, fieldName, fixedTargetValueList) {
      return data.sort((a, b) => {
        let aVal = a[fieldName].constructor !== Object ? a[fieldName] : a[fieldName].canUse
        let bVal = b[fieldName].constructor !== Object ? b[fieldName] : b[fieldName].canUse
        let isPercent
        switch (fieldName) {
          case 'memoryUsage':
            isPercent = false
            if (a.primaryAlias === this.targetName) {
              aVal = fixedTargetValueList.numeric
            }
            if (b.primaryAlias === this.targetName) {
              bVal = fixedTargetValueList.numeric
            }
            break
          case 'missingPortion':
            isPercent = true
            if (a.primaryAlias === this.targetName) {
              aVal = fixedTargetValueList.percent
            }
            if (b.primaryAlias === this.targetName) {
              bVal = fixedTargetValueList.percent
            }
            break
          case 'correlation':
            isPercent = true
            if (a.primaryAlias === this.targetName) {
              aVal = fixedTargetValueList.percent
            }
            if (b.primaryAlias === this.targetName) {
              bVal = fixedTargetValueList.percent
            }
            if (!a.correlation.includes('%')) {
              aVal = isDescending ? '0%' : '999%'
            }
            if (!b.correlation.includes('%')) {
              bVal = isDescending ? '0%' : '999%'
            }
            break
          case 'primaryAlias':
          case 'statsType':
          case 'dataQuality':
            isPercent = false
            if (a.primaryAlias === this.targetName) {
              aVal = fixedTargetValueList.unicode
            }
            if (b.primaryAlias === this.targetName) {
              bVal = fixedTargetValueList.unicode
            }
            break
        }
        for (let i in this.sortStatus) {
          if (this.sortStatus[i] !== fieldName) {
            this.sortStatus[i] = null
          }
          this.sortStatus[fieldName] = isDescending
        }

        if (!isDescending) {
          [bVal, aVal] = [aVal, bVal]
        }

        if (isPercent) {
          return bVal.split('%')[0] - aVal.split('%')[0]
        } else {
          return bVal > aVal ? 1 : -1
        }
      })
    },
    dataSizeUnitTransform (value) {
      let result
      if (value < 0) return '\u221E'
      if (value < 0.01) {
        result = this.formatComma((value * 1024).toFixed(2))
        if (+result === 0) {
          return '\u22480 KB'
        }
        result = `${result} KB`
      } else if (value > 999) {
        result = `${this.formatComma((value / 1024).toFixed(2))} GB`
      } else {
        result = `${this.formatComma((+value).toFixed(2))} MB`
      }
      return result
    },
    hasDuplicateSelectedDataColumn (row) {
      const hasSelectedColumn = this.selectedFactorList.findIndex((column) => column.dataColumnId === row.dataColumnId) > -1
      return this.selectedFactorList
        .filter((column) => column.primaryAlias === row.primaryAlias)
        .length > 1 && hasSelectedColumn
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  .tooltip-container {
    z-index: 1000;
  }
  .chosen {
    display: flex;
    margin-left: 16px;
    font-size: 12px;
    color: #CCCCCC;

    &__resources {
      margin-left: 24px;
    }

    &__size-exceed {
      margin-left: 12px;
      color: #FF5C46;
    }
  }
  .disable-label {
    color: #999999;
  }
  &__content--left {
    padding: 16px;
    background-color: #192323;
  }
  &__info-wrapper {
    position: relative;
    width: 100%;
  }
  ::v-deep .el-table {
    .caret-wrapper {
      visibility: hidden;
    }
    .no-checkbox {
      .el-checkbox__input{
        display: none;
      }
    }
  }
  .trigger {
    color: #2AD2E2
  }
  .warning-text {
    color: #FF5C46;
    vertical-align: text-bottom;
    margin-left: 5px;
  }
  .descending {
    transform: rotate3d(1, 0, 0, 180deg);
  }
  .correlation-container {
    display: flex;
    align-items: center;
    .item-bar {
      width: 60%;
      height: 6px;
      border-radius: 3px;
      margin-right: 5px;
      background: #323A3A;
      &__inner {
        height: 100%;
        border-radius: 3px;
        background: #2FECB3;
      }
    }
  }
  .calculating-status-block {
    display: flex;
    align-items: center;
    ::v-deep .spinner-block {
      margin-right: 6px;
      padding: 0;
    }
  }
  .input-field {
    margin-bottom: 24px;

    &__label {
      margin-bottom: 8px;
      font-size: 14px;
      color: #CCCCCC;
    }

    &__radio-group-container {
      display: flex;
      flex-direction: column;

      .input-radio-group {
        [lang="en"] & {
          display: flex;
          justify-content: space-between;
        }
        .input-radio-label {
          font-size: 14px;
          line-height: 18px;
        }
        &:not(:last-child) {
          margin: 0 0 8px 0;
        }
        .tooltip {
          padding: 12px;
          width: 200px;
          font-size: 12px;
          line-height: 16px;
          white-space: normal;
          color: #DDDDDD;

          [lang="en"] & {
            left: 70%;
          }
        }
      }
    }
  }
  .dialog-button-block {
    .btn {
      width: 100%;
    }
  }
}
</style>
