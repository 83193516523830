<template>
  <el-tabs v-model="activeName">
    <el-tab-pane
      :label="$t('modelPerformance.performance')"
      :name="$t('modelPerformance.performance')"
    >
      <div class="metric-block">
        <div :class="[`metric-block__${resultInfo.trainingType.toLowerCase()}`]">
          <div
            v-for="metric in Object.keys(resultInfo.metrics)"
            :key="metric"
          >
            {{ metric === 'R2' ? 'R-Squared' : metric }}: {{ formatComma(autoRound(resultInfo.metrics[metric])) }}
          </div>
        </div>
        <display-confusion-matrix
          v-if="resultInfo.trainingType === 'BINARY'"
          :info="resultInfo"
          :height="200"
          :font-size="13"
          class="metric-block__matrix"
        />
      </div>
    </el-tab-pane>
    <el-tab-pane
      :label="$t('modelTraining.trainingExplanation')"
      :name="$t('modelTraining.trainingExplanation')"
    >
      <template v-if="resultInfo.explanation.length">
        <div class="characteristic-factor">
          {{ $t('modelTraining.featureImportance') }}
          <remind-popover
            :content="$t('modelTraining.reminder.featureImportance')"
            icon-name="description"
            icon-color="#A7A7A7"
          />
        </div>
        <display-parallel-bar-chart
          :dataset="paralleChartGenerator(resultInfo.explanation)"
          :title="featureTitle"
          :is-show-label-data="true"
          :is-show-toolbox="false"
        />
      </template>
      <template v-else>
        <div class="no-feature-importance">
          {{ $t('modelExplanation.noModelSupported') }}
        </div>
      </template>
    </el-tab-pane>
    <el-tab-pane
      :label="$t('modelTraining.modelFeature')"
      :name="$t('modelTraining.modelFeature')"
    >
      <el-table
        :data="resultInfo.factors"
        style="width: 100%"
      >
        <el-table-column
          :label="$t('editing.columnName')"
          prop="primaryAlias"
        />
        <el-table-column
          :label="$t('modelTraining.type')"
          prop="statsType"
          width="auto"
        />
      </el-table>
    </el-tab-pane>
    <el-tab-pane
      :label="$t('modelTraining.trainingLog')"
      :name="$t('modelTraining.trainingLog')"
    >
      <div class="log-description">
        {{ $t('modelTraining.logDescription') }}
      </div>
      <el-table
        :data="log"
        style="width: 100%"
      >
        <el-table-column
          :label="$t('modelInfo.name')"
          prop="modelId"
          width="300"
        />
        <template v-if="resultInfo.trainingType === 'BINARY'">
          <el-table-column
            label="AUC"
            prop="auc"
            width="180"
          />
          <el-table-column
            label="AUCPR"
            prop="aucpr"
            width="auto"
          />
        </template>
        <template v-else>
          <el-table-column
            :label="$t('modelTraining.meanResidualDeviance')"
            prop="meanResidualDeviance"
            width="230"
          />
          <el-table-column
            label="RMSE"
            prop="rmse"
            width="180"
          />
          <el-table-column
            label="MSE"
            prop="mse"
            width="180"
          />
          <el-table-column
            label="MAE"
            prop="mae"
            width="180"
          />
          <!-- <el-table-column
            label="RMSLE"
            prop="rmsle"
            width="180"
          /> -->
        </template>
      </el-table>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import RemindPopover from '@/components/popover/RemindPopover.vue'

export default {
  name: 'ResultExpand',
  components: {
    RemindPopover
  },
  props: {
    resultInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      activeName: this.$t('modelPerformance.performance'),
      featureTitle: {
        xAxis: [
          {
            data_type: 'string',
            dc_id: 92483,
            dc_name: 'c2',
            display_name: this.$t('modelTraining.feature'),
            drillable: true,
            is_feature: null,
            lowerLimit: null,
            operator: null,
            stats_type: 'category',
            upperLimit: null
          }
        ],
        yAxis: [
          {
            data_type: 'string',
            dc_id: 92483,
            dc_name: 'c2',
            display_name: this.$t('modelTraining.score'),
            drillable: true,
            is_feature: null,
            lowerLimit: null,
            operator: null,
            stats_type: 'category',
            upperLimit: null
          }
        ]
      }
    }
  },
  computed: {
    log () {
      const logData = this.resultInfo.log
      const log = []
      let maxLength = 0
      Object.values(logData).forEach((metric) => {
        if (metric && metric.length > maxLength) {
          maxLength = metric.length
        }
      })
      if (this.resultInfo.trainingType === 'BINARY') {
        for (let i = 0; i < maxLength; i++) {
          let obj = {
            modelId: logData.model_id[i],
            auc: this.formatComma(this.autoRound(logData.auc[i])),
            aucpr: this.formatComma(this.autoRound(logData.aucpr[i]))
          }
          log.push(obj)
        }
      } else {
        for (let i = 0; i < maxLength; i++) {
          let obj = {
            modelId: logData.model_id[i],
            meanResidualDeviance: this.formatComma(this.autoRound(logData.mean_residual_deviance[i])),
            rmse: this.formatComma(this.autoRound(logData.rmse[i])),
            mse: this.formatComma(this.autoRound(logData.mse[i])),
            mae: this.formatComma(this.autoRound(logData.mae[i]))
            // rmsle: this.formatComma(this.autoRound(logData.rmsle[i]))
          }
          log.push(obj)
        }
      }
      return log
    }
  },
  methods: {
    paralleChartGenerator (explanation) {
      let explanationList = JSON.parse(JSON.stringify(explanation))
      let primaryAliasList = []
      let weightList = []
      explanationList
        .sort((a, b) => { return b.weight - a.weight })
        .map(item => {
          primaryAliasList.push([item.primaryAlias])
          weightList.push([item.weight])
        })
      return {
        columns: [this.$t('modelTraining.score')],
        display_columns: [this.$t('modelTraining.score')],
        data: weightList,
        index: primaryAliasList,
        display_index: primaryAliasList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.metric-block {
  width: 100%;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  color: #CCCCCC;
  &__regression {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-left: 6px;
  }
  &__binary {
    flex: 1 1 30%;
    margin-left: 6px;
    line-height: 28px;
  }
  &__matrix {
    flex: 2 2 70%;
  }
}

.characteristic-factor {
  font-weight: 700;
  color: #CCCCCC;
  padding-left: 20px;
}
.no-feature-importance {
  font-size: 14px;
  text-align: center;
  color: #999999;
}
.log-description {
  color: #CCCCCC;
  margin-bottom: 10px;
}
::v-deep .el-tabs {
  &>.el-tabs__header .el-tabs__item {
    border-bottom-color: #192323;
  }

  &__header {
    .el-tabs__nav::before {
      background: unset;
    }
    // 為了覆蓋 TrainingGoalResult 的樣式
    .el-tabs__item {
      padding: 0;
      border: none !important;

      &.is-active {
        background-color: #192323;
        border-radius: 8px 8px 0px 0px;
      }
    }

    .el-tabs__nav::before {
      height: 0 !important;
    }
  }

  &__nav-wrap::after {
    height: 0;
  }

  &__content {
    background-color: #192323;
    border-radius: 8px;
  }
}
</style>
