<template>
  <div class="training-goal">
    <div class="bread-crumb">
      <router-link
        :to="{ name: 'ModelTrainingList' }"
        class="link"
      >
        {{ $t('sideNav.modelTrainingList') }}
      </router-link>
      <span class="divider">/</span>{{ goalName }}
    </div>
    <div class="training-goal__title">
      {{ $t('modelTraining.trainingContent') }}
      <span
        v-if="unSavedModelCounts >= 10"
        class="training-goal__title--warning"
      >
        <svg-icon
          icon-class="alert-circle"
        />
        {{ $t('modelTraining.modelQuantityWarnings') }}
      </span>
    </div>
    <spinner
      v-if="isLoading"
      :title="$t('editing.loading')"
    />
    <section
      v-else
      class="training-goal__content"
    >
      <div class="training-goal__source">
        <div class="info-block">
          <div class="info-block__label">
            {{ $t('modelTraining.trainingName') }}：
          </div>
          <div class="info-block__text">
            {{ goalName }}
          </div>
        </div>
        <div class="info-block">
          <svg-icon
            icon-class="table"
            class="info-block__icon"
          />
          <div class="info-block__label">
            {{ $t('modelTraining.trainingData') }}：
          </div>
          <div class="info-block__text">
            {{ dataSourceName }} / {{ dataFrameName }}
          </div>
        </div>
        <div class="info-block">
          <svg-icon
            icon-class="target"
            class="info-block__icon"
          />
          <div class="info-block__label">
            {{ $t('modelTraining.trainingTarget') }}：
          </div>
          <div class="info-block__text">
            {{ targetDataColumnName }}
          </div>
        </div>
        <div
          v-if="targetColumnStatsType === 'CATEGORY' && primaryTarget"
          class="info-block"
        >
          <svg-icon
            icon-class="arrow-target"
            class="info-block__icon"
          />
          <div class="info-block__label">
            {{ $t('model.primaryTarget') }}：
          </div>
          <div class="info-block__text">
            {{ primaryTarget }}
          </div>
        </div>
        <div class="info-block">
          <svg-icon
            icon-class="Tag"
            class="info-block__icon"
          />
          <div class="info-block__label">
            {{ $t('model.trainingType') }}：
          </div>
          <div class="info-block__text">
            {{ $t(`modelInfo.${modelTrainingType.toLowerCase()}Model`) }}
          </div>
        </div>
      </div>
      <el-tabs
        v-model="activeTabName"
        class="training-goal__setting-tab"
        type="card"
      >
        <el-tab-pane
          :label="$t('modelTraining.trainingSetting')"
          :name="$t('modelTraining.trainingSetting')"
        >
          <training-goal-config
            :target-name="targetDataColumnName"
            :factor-data="dataColumnInfoList"
            :un-saved-model-counts="unSavedModelCounts"
            :is-calculating="isStartPollingCorrelation"
            :model-training-type="modelTrainingType"
            :primary-metrics="primaryMetrics"
            :training-strategy="trainingStrategy"
            :training-data-usage="trainingDataUsage"
            @done="trainCompleted"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('modelTraining.trainingResult')"
          :name="$t('modelTraining.trainingResult')"
        >
          <training-goal-result
            :active-result-list="activeResultList"
            :in-active-result-list="inActiveResultList"
            :fail-active-result-list="failActiveResultList"
            :un-saved-model-counts="unSavedModelCounts"
            :goal-name="goalName"
            :training-strategy="trainingStrategy"
            :model-training-type="modelTrainingType"
            @register="registerModel"
            @delete="deleteTask"
            @close="closeLogDisplay"
          />
        </el-tab-pane>
      </el-tabs>
    </section>
  </div>
</template>

<script>
import TrainingGoalConfig from './components/TrainingGoalConfig'
import TrainingGoalResult from './components/TrainingGoalResult'
import {
  getModelTrainingGoalConfiguration,
  getModelTrainingGoalCorrelation,
  getModelTrainingGoalResult,
  deleteModelTrainingTask,
  registerModelTrainingTask,
  closeModelTrainingTaskDisplay
} from '@/API/ModelTraining'
import { Message } from 'element-ui'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'TrainingGoal',
  components: {
    TrainingGoalConfig,
    TrainingGoalResult
  },
  data () {
    return {
      isLoading: false,
      intervalFunction: null,
      intervalCorrelationFunction: null,
      goalName: '',
      dataSourceName: '',
      dataFrameName: '',
      targetDataColumnName: '',
      modelTrainingType: '',
      primaryMetrics: [],
      isStartPollingResult: false,
      isStartPollingCorrelation: false,
      addSpeedyOption: false,
      dataColumnInfoList: [],
      activeResultList: [],
      failActiveResultList: [],
      inActiveResultList: [],
      trainingStrategy: [],
      trainingDataUsage: {},
      activeTabName: this.$t('modelTraining.trainingSetting'),
      primaryTarget: null,
      targetColumnStatsType: null
    }
  },
  computed: {
    ...mapState('modelManagement', ['isComeFromModelInfo']),
    modelTrainingGoalId () {
      return this.$route.params.goal_id
    },
    unSavedModelCounts () {
      let count = 0
      this.activeResultList.forEach(model => {
        !model.registered && count++
      })
      this.inActiveResultList.length && this.inActiveResultList.forEach(model => { count++ })
      return count
    }
  },
  mounted () {
    if (localStorage.getItem('demoTrainingStrategy') === 'true') this.addSpeedyOption = true
    if (this.isComeFromModelInfo) this.activeTabName = this.$t('modelTraining.trainingResult')
    this.fetchData()
  },
  destroyed () {
    this.clearPolling()
    this.clearCorrelationPolling()
    this.setIsComeFromModelInfo(false)
    this.setTrainingTaskId(null)
  },
  methods: {
    ...mapMutations('modelManagement', ['setIsComeFromModelInfo', 'setTrainingTaskId']),
    async fetchData () {
      this.isLoading = true
      await this.fetchGoalConfiguration()
      await this.fetchCorrelation()
      await this.fetchGoalResult()
      this.isLoading = false
    },
    fetchGoalConfiguration () {
      return getModelTrainingGoalConfiguration({
        speedy: this.addSpeedyOption,
        trainingGoalId: this.modelTrainingGoalId
      })
        .then(response => {
          this.goalName = response.name
          this.dataSourceName = response.dataSourceName
          this.dataFrameName = response.dataFrameName
          this.targetDataColumnName = response.targetDataColumnName
          this.modelTrainingType = response.trainingType
          this.primaryMetrics = response.primaryMetrics
          this.primaryTarget = response.primaryTarget
          this.trainingStrategy = response.trainingStrategy
          this.targetColumnStatsType = response.targetColumnStatsType
          this.trainingDataUsage = {
            totalUsage: this.roundNumber(response.totalUsage),
            maximumTotalUsage: this.roundNumber(response.maximumTotalUsage),
            concurrentUsage: this.roundNumber(response.concurrentUsage),
            maximumConcurrentUsage: this.roundNumber(response.maximumConcurrentUsage)
          }
          this.dataColumnInfoList = response.dataColumnInfoList.filter(data => data.statsType !== 'DATETIME')
            // 相關度從大到小排，目標欄位會是第一個
            .map(info => {
              return {
                ...info,
                missingPortion: this.autoRound(info.missingPortion * 100) + '%'
              }
            })
        })
        .catch(() => {
          this.$router.push({ name: 'ModelTrainingList' })
        })
    },
    fetchCorrelation () {
      return getModelTrainingGoalCorrelation(this.modelTrainingGoalId)
        .then(response => {
          if (response.status === 'Complete') {
            this.isStartPollingCorrelation = false
            this.clearCorrelationPolling()
            // 目前correlation有可能和configureing會對不起來(遺失率太高算null出來被濾掉了?) 只能先造資料
            const idArray = this.dataColumnInfoList.map(data => data.dataColumnId)
            const correlationIdArray = response.dataColumnCorrelationInfoList.map(data => data.dataColumnId)
            const diffIdArray = idArray.filter(id => {
              return correlationIdArray.indexOf(id) === -1
            })
            const tmpArray = []
            diffIdArray.forEach((id) => {
              tmpArray.push({})
              tmpArray[tmpArray.length - 1].dataColumnId = id
              tmpArray[tmpArray.length - 1].correlation = this.$t('modelTraining.correlationCalculatingFail')
            })
            const dataColumnCorrelationInfoList = response.dataColumnCorrelationInfoList.concat(tmpArray)
            this.dataColumnInfoList.forEach(data => {
              data.correlation = dataColumnCorrelationInfoList.filter(info => info.dataColumnId === data.dataColumnId)[0].correlation
              if (!data.correlation) {
                data.correlation = this.$t('modelTraining.correlationCalculatingFail')
                data.calculateFail = true
              } else if (typeof (data.correlation) === 'number') {
                data.correlation = this.roundNumber(Math.abs(data.correlation * 100)) + '%'
              }

              if (!data.correlation.includes('%')) {
                data.calculateFail = true
              }
            })
            this.dataColumnInfoList.sort((a, b) => {
              let aVal = a.correlation
              let bVal = b.correlation
              if (!aVal.includes('%') || aVal === null) {
                aVal = '-1%'
              }
              if (!bVal.includes('%') || bVal === null) {
                bVal = '-1%'
              }
              return bVal.split('%')[0] - aVal.split('%')[0]
            })
          } else {
            if (!this.isStartPollingCorrelation) {
              this.isStartPollingCorrelation = true
              this.dataColumnInfoList.sort((a, b) => {
                let aVal = a.missingPortion
                let bVal = b.missingPortion
                if (a.primaryAlias === this.targetDataColumnName) {
                  aVal = '-1%'
                }
                return aVal.split('%')[0] - bVal.split('%')[0]
              })
              this.startPollingCorrelation()
            }
          }
        })
    },
    fetchGoalResult () {
      return getModelTrainingGoalResult(this.modelTrainingGoalId)
        .then(({ resultInfoDtoList }) => {
          /* 注意： activeResultList 之所以不直接 reassign 是因為
           * reassign 會更新 data 並造成組件裡 table expand 自動合起來
           * 因此對比完確定要更新資料才會 assign
          */
          let processList = resultInfoDtoList.filter(result => result.status === 'Process' || result.status === 'Ready')
          if (processList.length && !this.isStartPollingResult) {
            this.isStartPollingResult = true
            this.startPolling()
          } else if (!processList.length) {
            this.isStartPollingResult = false
            this.clearPolling()
          }
          this.inActiveResultList = processList
          this.failActiveResultList = resultInfoDtoList.filter(result => result.status === 'Fail')
          let tmpActiveResultList = resultInfoDtoList.filter(result => result.status === 'Complete')
          tmpActiveResultList = this.sortResultList(tmpActiveResultList)
          if (JSON.stringify(this.activeResultList) !== JSON.stringify(tmpActiveResultList)) {
            this.activeResultList = tmpActiveResultList
          }
        })
    },
    registerModel (data) {
      const taskInfo = this.activeResultList.filter(result => result.taskId === data.id)[0]
      const trainingTargetStatsType = this.dataColumnInfoList.filter(data => data.primaryAlias === this.targetDataColumnName)[0].statsType
      registerModelTrainingTask({
        ...data,
        ioArgs: {
          input: taskInfo.factors.map(factor => {
            return {
              modelColumnName: factor.primaryAlias,
              statsType: factor.statsType
            }
          }),
          output: [{
            modelColumnName: this.targetDataColumnName,
            statsType: trainingTargetStatsType
          }]
        }
      })
        .then(() => {
          Message({
            message: this.$t('modelTraining.saveModelSuccessfully'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
          this.$router.push({
            name: 'ModelList'
          })
        })
    },
    deleteTask (id) {
      deleteModelTrainingTask(id)
        .then(() => {
          this.fetchGoalResult()
          Message({
            message: this.$t('modelTraining.deleteModelSuccessfully'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        })
    },
    closeLogDisplay (task_id) {
      closeModelTrainingTaskDisplay(task_id)
        .then(() => {
          this.fetchGoalResult()
          Message({
            message: this.$t('modelTraining.closeLogDisplaySuccessfully'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        })
    },
    // TODO: 資料操作有改變時，先 clearPolling 再重新 startPolling
    startPolling () {
      this.intervalFunction = window.setInterval(() => {
        this.fetchGoalResult()
      }, 5000)
    },
    startPollingCorrelation () {
      this.intervalCorrelationFunction = window.setInterval(() => {
        this.fetchCorrelation()
      }, 3000)
    },
    clearPolling () {
      if (this.intervalFunction) window.clearInterval(this.intervalFunction)
    },
    clearCorrelationPolling () {
      if (this.intervalCorrelationFunction) window.clearInterval(this.intervalCorrelationFunction)
    },
    sortResultList (resultList) {
      // const getMetricValue = metrics => metrics.filter(option => option.metric === primaryMetric)[0].value
      return resultList.sort((a, b) => b.taskId - a.taskId)
    },
    trainCompleted () {
      this.activeTabName = this.$t('modelTraining.trainingResult')
      this.fetchGoalResult()
    }
  }
}
</script>

<style lang="scss" scoped>
.training-goal {
  height: 100%;
  display: flex;
  flex-direction: column;

  .bread-crumb {
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 18px;
    color: #DDDDDD;
    .link {
      font-weight: 600;
    }
    .divider {
      padding: 0 4px;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    &--warning {
      margin-left: 20px;
      font-size: 12px;
      vertical-align: middle;
      color: #FFDF6F;
    }
  }

  &__source {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .info-block {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &__label, &__text {
        font-size: 12px;
        line-height: 20px;
        color: #FFFFFF;
      }

      &__label {
        font-weight: 600;
        color: #A7A7A7;
      }

      &__icon {
        margin: auto 4px auto 0;
        color: #A7A7A7;
      }
    }
  }

  &__setting-tab {
    flex: 1;
    width: 100%;
    min-height: 0;
  }

  ::v-deep .panel {
    padding-top: 20px;

    &__index {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid var(--color-theme);
      font-size: 14px;
      line-height: 14px;
    }

    &__content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      height: 100%;

      &--left, &--right {
        border-radius: 5px;
        overflow: hidden;

        .empty-message {
          font-size: 14px;
          color: #666666;
        }
      }

      &--left {
        flex: 1 1 auto;
        margin-right: 8px;
        height: 100%;
      }

      &--right {
        flex-basis: 320px;
        padding: 16px;
        background-color: #192323;
      }
    }

    &__header-wrapper {
      display: flex;
      align-items: center;
      height: 30px;
      margin-bottom: 16px;
    }

    &__title {
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
    }

    &__info-wrapper {
      height: calc(100% - 46px);
      overflow: auto;
    }
  }
  ::v-deep .el-tabs {
    &>.el-tabs__header {
      border: none;
      margin: 0;
      width: 100%;

      .el-tabs__nav {
        position: relative;
        width: 100%;
        border: none;
        overflow-x: auto;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          background: #324B4E;
        }
      }
      .el-tabs__item {
        border: none;
        color:  #646464;
        border-bottom: 1px solid #3B4343;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: top;
        &.is-active {
          color: $theme-text-color-primary;
          border-bottom: 3px solid $theme-text-color-primary;
        }
      }
    }

    &>.el-tabs__content {
      height: calc(100% - 40px);

      .el-tab-pane, .spinner-block, .panel, .panel__content {
        height: 100%;
      }
    }
  }

  ::v-deep .el-table {
    background-color: transparent;
    color: #FFFFFF;;

    &::before {
      background-color: transparent;
    }

    .el-checkbox__inner {
      background-color: unset;
      border-radius: 0;
      &:hover {
        border-color: $theme-color-primary;
      }
    }

    .is-focus > .el-checkbox__inner,
    .is-indeterminate > .el-checkbox__inner {
      border-color: $theme-color-primary;
    }

    .is-checked > .el-checkbox__inner {
      border-color: $theme-color-primary;
      background-color: $theme-color-primary;
    }

    tr, th {
      font-size: 13px;
      background-color: transparent;
    }

    th.is-leaf, td {
      border-bottom: 1px solid #323A3A;
    }

    td {
      padding: 7px 0;
    }

    tr.el-table__row.hover-row > td {
      background-color: transparent;
    }

    &.el-table--enable-row-hover .el-table__body tr {
      &:hover > td {
        background-color: #253030;
      }
      &.is-hovered:hover > td {
        background-color: #253030;
        cursor: pointer;
      }
    }

    thead {
      color: #CCCCCC;
    }

    .el-table__body-wrapper {
      overflow: auto;
    }

    .el-table__body tr.current-row > td {
      background-color: transparent;
    }
  }
}
</style>
