<template>
  <div class="training-goal__setting-panel panel">
    <div class="panel__content">
      <div class="panel__content--left">
        <div class="panel__header-wrapper">
          <div class="panel__index">
            3
          </div>
          <div class="panel__title">
            {{ $t('modelTraining.saveToModelManagement') }}
            <remind-popover
              :content="$t('modelTraining.reminder.saveToModelManagement')"
              width="auto"
              icon-name="description"
            />
          </div>
          <div class="unsaved-model">
            <div class="unsaved-model__counts">
              {{ $t('modelTraining.trainingLimitation') }}: {{ unSavedModelCounts }}/10
              <remind-popover
                :content="$t('modelTraining.reminder.trainingCount')"
                icon-name="description"
              />
            </div>
            <div
              v-if="unSavedModelCounts >= 10"
              class="unsaved-model__limit"
            >
              <svg-icon icon-class="alert" />
              {{ $t('modelTraining.exceedLimitAmount') }}
            </div>
          </div>
        </div>
        <div class="panel__info-wrapper">
          <div
            v-if="activeResultList.length === 0"
            class="empty-message"
          >
            {{ $t('modelTraining.noTrainedModel') }}
          </div>
          <template
            v-else
            class="panel__info-wrapper"
          >
            <el-table
              ref="multipleTable"
              :data="activeResultList"
              highlight-current-row
              height="100%"
              max-height="100%"
              class="result-tab"
              style="width: 100%"
              @cell-click="handleClick"
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <result-expand
                    :result-info="props.row"
                  />
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('modelTraining.modelInfo')"
                show-overflow-tooltip
              >
                <template slot-scope="props">
                  <div class="info-block">
                    <span class="info-block__label">{{ $t('modelTraining.taskId') }}：</span>
                    <span class="info-block__text">{{ props.row.taskId }}</span>
                  </div>
                  <div class="name">
                    {{ props.row.name }}
                  </div>
                  <div class="info-block">
                    <span class="info-block__label">{{ $t('modelTraining.primaryMetric') }}：</span>
                    <span class="info-block__text">{{ props.row.primaryMetric }}</span>
                  </div>
                  <div class="info-block">
                    <span class="info-block__label">{{ $t('modelTraining.trainingStrategy') }}：</span>
                    <span class="info-block__text">{{ trainingStrategyList[props.row.trainingStrategy] }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                show-overflow-tooltip
              >
                <template
                  slot="header"
                  slot-scope="scope"
                >
                  <default-select
                    v-model="metricSelected"
                    :option-list="metricsSelectionList"
                    :popper-append-to-body="true"
                    class="metric-selector"
                    @change="setPrimaryMetric"
                  />

                  <el-popover
                    :offset="130"
                    width="548"
                    trigger="hover"
                    popper-class="el-popover--training-reminder"
                  >
                    <svg-icon
                      slot="reference"
                      style="color: #A7A7A7"
                      icon-class="description"
                    />
                    <metric-content
                      :metrics-type="lowercaseEachLetterExceptFirst(modelTrainingType)"
                      :display-metric="metricSelected"
                    />
                  </el-popover>
                </template>
                <template slot-scope="props">
                  {{ getMetricValue(props.row.metrics) }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('modelTraining.updatedTime')"
                width="160"
                show-overflow-tooltip
              >
                <template slot-scope="props">
                  {{ (props.row.updatedAt && timeToDateTime(props.row.updatedAt)) || '-' }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('editing.action')"
                width="200"
              >
                <template slot-scope="props">
                  <div class="action-block">
                    <button
                      :disabled="props.row.registered"
                      :class="{'btn-default': !props.row.registered, 'btn-outline': props.row.registered}"
                      type="submit"
                      class="btn-m btn-register"
                      @click.stop="registerModel(props.row.taskId)"
                    >
                      <span v-if="props.row.registered"><svg-icon icon-class="checked" />{{ $t('modelTraining.saved') }}</span>
                      <span v-else>{{ $t('modelTraining.saveToModelManagement') }}</span>
                    </button>
                    <button
                      v-if="props.row.registered"
                      class="btn-m btn-outline btn-link"
                      @click.stop="goToModelInfo(props.row.registeredModelId)"
                    >
                      <svg-icon icon-class="chain" />
                    </button>
                    <button
                      class="btn-outline btn-delete"
                      @click.stop="deleteModel(props.row.taskId)"
                    >
                      <svg-icon icon-class="deleteV2" />
                    </button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </div>
      </div>
      <div class="panel__content--right">
        <div class="panel__header-wrapper">
          <div class="panel__title">
            {{ $t('modelTraining.trainingList') }}
          </div>
        </div>
        <el-tabs
          v-model="activeTabName"
          type="card"
        >
          <el-tab-pane
            :label="trainingTabLabel"
            :name="tabName.training"
          >
            <training-result-log
              :result-list="inActiveResultList"
            />
          </el-tab-pane>
          <el-tab-pane
            :label="finishTabLabel"
            :name="tabName.finish"
          >
            <training-result-log
              :result-list="finishLogList"
              @closeLog="passCloseEvent"
            />
          </el-tab-pane>
          <el-tab-pane
            :label="failTabLabel"
            :name="tabName.fail"
          >
            <training-result-log
              :result-list="failLogList"
              @closeLog="passCloseEvent"
              @delete="confirmDelete"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <writing-dialog
      v-if="isShowRegisteredDialog"
      :title="$t('modelTraining.saveToModelManagement')"
      :button="$t('button.save')"
      @closeDialog="closeRegisterDialog"
      @confirmBtn="registerTaskToModel"
    >
      <div class="register-reminder">
        {{ $t('modelTraining.reminder.saveModel') }}
      </div>
      <input-verify
        v-validate="`required|max:${max}`"
        v-model="modelName"
        :placeholder="$t('editing.pleaseEnterNewModelName')"
        type="text"
        name="modelName"
      />
    </writing-dialog>
    <decide-dialog
      v-if="isShowDeleteDialog"
      :title="$t('modelTraining.decideToDeleteModel')"
      :type="'confirm'"
      :btn-text="$t('button.delete')"
      @closeDialog="cancelDelete"
      @confirmBtn="confirmDelete"
    />
  </div>
</template>

<script>
import ResultExpand from './ResultExpand'
import TrainingResultLog from './TrainingResultLog'
import MetricContent from './MetricContent'
import InputVerify from '@/components/InputVerify'
import RemindPopover from '@/components/popover/RemindPopover.vue'
import WritingDialog from '@/components/dialog/WritingDialog'
import DefaultSelect from '@/components/select/DefaultSelect'
import DecideDialog from '@/components/dialog/DecideDialog'
import { Message } from 'element-ui'
import { mapState } from 'vuex'

export default {
  name: 'TrainingGoalResult',
  inject: ['$validator'],
  components: {
    RemindPopover,
    ResultExpand,
    InputVerify,
    WritingDialog,
    DefaultSelect,
    DecideDialog,
    TrainingResultLog,
    MetricContent
  },
  props: {
    activeResultList: {
      type: Array,
      default: () => []
    },
    inActiveResultList: {
      type: Array,
      default: () => []
    },
    failActiveResultList: {
      type: Array,
      default: () => []
    },
    unSavedModelCounts: {
      type: Number,
      default: null
    },
    goalName: {
      type: String,
      default: null
    },
    trainingStrategy: {
      type: Array,
      default: () => []
    },
    modelTrainingType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isShowRegisteredDialog: false,
      isShowDeleteDialog: false,
      registerTaskId: null,
      deleteTaskId: null,
      modelName: '',
      trainingName: '',
      metricSelected: this.modelTrainingType === 'REGRESSION' ? 'MAE' : 'AUC',
      tabName: {
        training: this.$t('modelTraining.training'),
        finish: this.$t('modelTraining.finish'),
        fail: this.$t('modelTraining.fail')
      },
      activeTabName: this.$t('modelTraining.training')
    }
  },
  watch: {
    inActiveResultList (newValue, oldValue) {
      if (newValue.length > oldValue.length) {
        this.activeTabName = this.$t('modelTraining.training')
      }
    },
    activeResultList (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.activeTabName = this.$t('modelTraining.finish')
      }
    },
    failActiveResultList (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.activeTabName = this.$t('modelTraining.fail')
      }
    }
  },
  computed: {
    ...mapState('modelManagement', ['isComeFromModelInfo', 'trainingTaskId']),
    max () {
      return this.$store.getters['validation/fieldCommonMaxLength']
    },
    finishLogList () {
      return this.activeResultList.filter(result => result.display)
    },
    failLogList () {
      return this.failActiveResultList.filter(result => result.display)
    },
    metricsSelectionList () {
      if (this.activeResultList.length) {
        let metrics = this.activeResultList[0].metrics
        return Object.keys(metrics).map(metric => {
          let rsquare
          if (metric === 'R2') {
            rsquare = 'R-Squared'
          }
          return {
            name: rsquare || metric,
            value: metric
          }
        })
      }
      return null
    },
    trainingStrategyList () {
      const trainingStrategyList = {}
      for (let strategy of this.trainingStrategy) {
        trainingStrategyList[strategy] = this.$t(`modelTraining.${strategy.toLowerCase()}`)
      }
      return trainingStrategyList
    },
    trainingTabLabel () {
      return `${this.$t('modelTraining.training')}(${this.inActiveResultList.length})`
    },
    finishTabLabel () {
      return `${this.$t('modelTraining.finish')}(${this.finishLogList.length})`
    },
    failTabLabel () {
      return `${this.$t('modelTraining.fail')}(${this.failLogList.length})`
    }
  },
  mounted () {
    let targetColumn = this.activeResultList.filter(result => result.taskId == this.trainingTaskId)
    if (this.isComeFromModelInfo && this.trainingTaskId && this.activeResultList.length) {
      if (targetColumn.length) {
        this.$refs.multipleTable.toggleRowExpansion(targetColumn[0])
      } else {
        Message({
          message: this.$t('modelTraining.noModelInfo'),
          type: 'warning',
          duration: 3 * 1000,
          showClose: true
        })
      }
    }
  },
  methods: {
    registerModel (taskId) {
      this.registerTaskId = taskId
      this.isShowRegisteredDialog = true
    },
    closeRegisterDialog () {
      this.modelName = ''
      this.registerTaskId = null
      this.isShowRegisteredDialog = false
    },
    registerTaskToModel () {
      this.$validator.validateAll().then(isValidate => {
        if (!isValidate) return
        this.$validator.detach('modelName')
        this.$emit('register', { id: this.registerTaskId, name: this.modelName })
        this.closeRegisterDialog()
      })
    },
    getMetricValue (metrics) {
      return this.formatComma(this.autoRound(metrics[Object.keys(metrics).filter(metric => metric === this.metricSelected)[0]]))
    },
    setPrimaryMetric (value) {
      this.metricSelected = value
    },
    handleClick (row) {
      this.$refs.multipleTable.toggleRowExpansion(row)
    },
    deleteModel (taskId) {
      this.deleteTaskId = taskId
      this.isShowDeleteDialog = true
    },
    cancelDelete () {
      this.deleteTaskId = null
      this.isShowDeleteDialog = false
    },
    confirmDelete (taskId = null) {
      this.$emit('delete', taskId || this.deleteTaskId)
      this.deleteTaskId = null
      this.isShowDeleteDialog = false
    },
    goToModelInfo (model_id) {
      this.$router.push({
        name: 'ModelInfo',
        params: { model_id }
      })
    },
    passCloseEvent (task_id) {
      this.$emit('close', task_id)
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  .unsaved-model {
    display: flex;
    margin-left: 24px;
    font-size: 12px;
    padding-top: 3px;

    &__counts {
      color: #CCCCCC;
    }

    &__limit {
      color: #FF5C46;
      margin-left: 12px;
    }
  }

  &__content {
    &--left {
      padding: 0;

      .action-block {
        display: flex;

        .btn-register {
          height: 36px;
          &.btn-outline {
            color: #2FECB3;
            background-color: transparent;
            border: 1px solid #515959;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          &.btn-default {
            margin-right: 8px;
            [lang="en"] & {
              padding: 0 7px;
            }
          }
        }

        .btn-link {
          height: 36px;
          margin-right: 8px;
          &.btn-m {
            padding: 0 10px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            min-width: 0px;
            color: #A7A7A7;
          }
          &.btn-outline {
            background-color: transparent;
            border: 1px solid #515959;
            &:hover {
              color: #979797;
              border-color: #A7A7A7;
            }
          }
        }

        .btn-delete {
          width: 36px;
          height: 36px;
          font-size: 14px;
          border-radius: 4px;
        }
      }
    }

    ::v-deep .info-block {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &__label, &__text {
        font-size: 12px;
        line-height: 20px;
        color: #FFFFFF;
      }

      &__label {
        font-weight: 600;
        color: #A7A7A7;
      }

      &__title {
        font-size: 18px;
        color: #FFFFFF;
      }
    }

    ::v-deep .el-input {
      .el-input__inner {
        padding: 0;
        width: 120px;
        height: auto;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        color: #CCCCCC;
      }

      .el-input__suffix {
        right: -3px;
      }
      .el-select__caret {
        height: 15px;
        line-height: 15px;
      }
    }

    ::v-deep .el-table {
      &__expand-icon {
        color: $theme-color-primary;
      }

      &__expanded-cell {
        background-color: transparent;
      }
    }

    ::v-deep .el-tabs {
      &__active-bar {
        background-color: transparent;
      }

      &>.el-tabs__header {
        .el-tabs__nav {
          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 1px;
          }
        }
        .el-tabs__item {
          width: 130px;

          [lang="en"] & {
            width: 160px;
          }
        }
      }

      &>.el-tabs__content {
        padding: 20px 10px;
      }
    }

    &--right {
      display: flex;
      flex-direction: column;
      height: 100%;

      ::v-deep .el-tabs {
        &>.el-tabs__header {
          .el-tabs__item {
            width: 95px;
            padding: 0 15px;

            [lang="en"] & {
              padding: 0;
              width: 95px;
            }
          }
        }
      }
    }
  }

  .register-reminder {
    margin: 15px 0;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #DDDDDD;
  }
}
</style>
