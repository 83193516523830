<template>
  <div class="log-info__wrapper">
    <div
      v-if="resultList.length === 0"
      class="empty-message"
    >
      {{ $t('modelTraining.noTrainedTask') }}
    </div>
    <div v-else>
      <div
        v-for="task in resultList"
        :key="task.taskId"
        class="task"
      >
        <template>
          <div class="task__header">
            <div class="info-block">
              <span class="info-block__label">{{ $t('modelTraining.taskId') }}：</span>
              <span class="info-block__title">{{ task.taskId }}</span>
            </div>
            <training-status v-if="task.status === 'Ready'">
              {{ $t('modelTraining.waiting') }}
            </training-status>
            <training-status v-else-if="task.status === 'Process'">
              {{ $t('modelTraining.training') }}
            </training-status>
            <div
              v-else-if="task.status === 'Fail'"
              @click="$emit('delete', task.taskId)"
            >
              <svg-icon
                icon-class="deleteV2"
                class="task__close"
              />
            </div>
            <div
              v-else
              @click="$emit('closeLog', task.taskId)"
            >
              <svg-icon
                icon-class="deleteV2"
                class="task__close"
              />
            </div>
          </div>
          <div class="info-block">
            <span class="info-block__label">{{ $t('modelTraining.factorAmount') }}：</span>
            <span class="info-block__text">{{ task.factors.length }}</span>
          </div>
          <div class="info-block">
            <span class="info-block__label">{{ $t('modelTraining.primaryMetric') }}：</span>
            <span class="info-block__text">{{ task.primaryMetric }}</span>
          </div>
          <div class="info-block">
            <span class="info-block__label">{{ $t('modelTraining.trainingStrategy') }}：</span>
            <span class="info-block__text">{{ trainingStrategyExplanationList[task.trainingStrategy] }}</span>
          </div>
          <div class="info-block">
            <span class="info-block__label">{{ infoLabelTime }}：</span>
            <span class="info-block__text">{{ (task.updatedAt && timeToDateTime(task.updatedAt)) || '-' }}</span>
          </div>
          <div
            v-if="task.status === 'Complete' || task.status === 'Fail'"
            class="info-block"
          >
            <span class="info-block__label">{{ !task.errorMessage ? $t('modelTraining.resultName') : $t('editing.errorReason') }}：</span>
            <span class="info-block__text">{{ !task.errorMessage ? task.name : task.errorMessage }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TrainingStatus from './TrainingStatus'

export default {
  name: 'TrainingResultLog',
  components: {
    TrainingStatus
  },
  props: {
    resultList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      trainingStrategyExplanationList: {
        SPEED: `${this.$t('modelTraining.speed')}（${this.$t('modelTraining.seconds')}）`,
        FAST: `${this.$t('modelTraining.fast')}（${this.$t('modelTraining.minutes')}）`,
        BALANCED: `${this.$t('modelTraining.balanced')}（${this.$t('modelTraining.hours')}）`,
        ACCURATE: `${this.$t('modelTraining.accurate')}（${this.$t('modelTraining.oneDay')}）`
      }
    }
  },
  computed: {
    infoLabelTime () {
      if (!this.resultList.length) return null
      let label
      let task = this.resultList[0]
      if (task.status === 'Process' || task.status === 'Ready') {
        return this.$t('editing.startTime')
      }
      task.status === 'Fail' ? label = this.$t('editing.endTime') : label = this.$t('modelTraining.updatedTime')
      return label
    }
  }
}
</script>

<style lang="scss" scoped>

.log-info__wrapper {
  height: 48vh;
  overflow: auto;
  .empty-message {
    text-align: center;
  }
  .task {
    display: flex;
    flex-direction: column;
    padding: 11px 12px;
    font-size: 14px;
    background-color: hsl(180, 13%, 17%);
    border-radius: 5px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .info-block {
        display: flex;
        flex-direction: column;
      }
    }

    &__close {
      cursor: pointer;
      color: #FFFFFF;
      font-size: 16px;
      &:hover {
        color: #DDDDDD;
        transition: color 0.2s ease-out;
      }
    }
  }
}
</style>
