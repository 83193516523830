<template>
  <div
    class="dialog-box"
    @click="closeDialog"
  >
    <div
      class="dialog-inner-box"
      @click.stop
    >
      <div @click="closeDialog">
        <svg-icon
          icon-class="close"
          class="icon dialog-close"
        />
      </div>
      <div
        v-if="content"
        class="dialog-content"
      >
        {{ content }}
      </div>
      <button
        class="btn btn-default"
        @click="closeDialog"
      >
        {{ $t('button.confirm') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RemindDialog',
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-content {
  padding: 28px;
  font-size: 18px;
}
</style>
